import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { Optional } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';
import { getPaymentDescription } from '../../../../utils/payment/payment';
import { isCalendarPage } from '../../../../utils/presets';

export type SelectableServicesViewModel = {
  ctaLabel: string;
  services: { id: string; slug: string; name: string; price: string }[];
};

export const memoizedSelectableServicesViewModel: MemoizedViewModalFactory<
  Optional<SelectableServicesViewModel>
> = {
  dependencies: {
    state: ['availableServices'],
  },
  createViewModel: createSelectableServicesViewModel,
};

export function createSelectableServicesViewModel({
  context,
  state,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<SelectableServicesViewModel> {
  const { t, wixSdkAdapter, experiments, preset } = context;
  const { rescheduleBookingDetails, availableServices } = state;

  const isMultipleSlugsEnabled = experiments.enabled(
    'specs.bookings.multipleServiceSlugs',
  );

  const shouldCreateViewModel =
    isCalendarPage(preset) &&
    wixSdkAdapter.isBackFromFormWithCart() &&
    !rescheduleBookingDetails &&
    availableServices.length > 1;

  if (!shouldCreateViewModel) {
    return;
  }

  const ctaLabel = t('app.change-service.text');
  const services: SelectableServicesViewModel['services'] = availableServices
    .slice(1)
    .map((service) => ({
      name: service.info.name,
      price: getPaymentDescription({
        context,
        service,
      }),
      id: service.id,
      slug: isMultipleSlugsEnabled
        ? service.info.slugs?.[0]!
        : service.info.slug!,
    }));

  return {
    ctaLabel,
    services,
  };
}
