import { bookingsCalendarClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';

export type OnSelectService = (serviceId: string, serviceSlug: string) => void;

export const createOnSelectService =
  ({
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>): OnSelectService =>
  (serviceId, serviceSlug) => {
    const [state] = getControllerState();
    const { selectedTimezone } = state;
    const { wixSdkAdapter, biLogger } = context;

    const referral = wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.REFERRAL,
    );

    void biLogger.report(
      bookingsCalendarClick({
        component: WidgetComponents.SELECT_SERVICE,
        element: WidgetElements.SELECTED_SERVICE,
        serviceId,
      }),
    );

    wixSdkAdapter.navigateToBookingsCalendarPage(serviceSlug, {
      timezone: selectedTimezone,
      referral,
    });
  };
